$control-width: 21rem;
$link-color: #000;
$mydna-blue: #30b5e8;

.AddressCapture-root-122 label.address-search-label {
  font-weight: bold;
  width: 20rem;
}

.toggle-button {
  border: none !important;
  margin-right: 10px;
}
.MuiFormControl-root.MuiTextField-root {
  margin: 1rem;
  width: $control-width;
}

.bold {
  font-weight: bold;
}

.TermsAndConditionsLink {
  color: $link-color !important;
}

$small-device-width: 769px;

@media (min-width: $small-device-width) {
  .mobile {
    display: none;
  }
}

@media (max-width: $small-device-width) {
  .desktop {
    display: none;
  }
}

.inline-buttons {
  div {
    display: inline-block;
    margin: auto;
  }

  button {
    min-width: 10rem;
    width: 21rem;
    margin: 1rem;
  }
}

.input-control {
  width: $control-width;
}
.input-control-short {
  width: 12rem !important;
  margin-right: -5px;
}

/*
h1 {
  color: $mydna-blue;
  font-family: AvenirRoman;
  font-weight: 400;
  font-style: normal;
  font-size: 38px;
  line-height: 52px;
  text-transform: uppercase;
}
*/

.title {
  color: #959595;
  font-family: 'AvenirRoman', sans-serif;
  font-style: normal;
  font-size: 18px;
  line-height: 25px;
  font-weight: 600;
  font-variant: normal;
  text-transform: uppercase;
  margin: 1.15rem auto 1rem auto;
  width: $control-width;
  letter-spacing: 1.8px;
  text-align: center;
}

.img-container {
  padding-top: 40px;
  text-align: center;

  img {
    width: 130px;
  }
}

.mydna-expansion-panel-item {
  margin-top: -10px;
  margin-left: -15px;
}
.mydna-expansion-panel-item-barcode {
  margin-top: -10px;
}
.mydna-expansion-panel-summary {
  align-self: baseline;
  width: 20rem;
}

.mydna-expansion-panel-summary-title {
  float: left;
  padding-right: 100px;
}

.mydna-expansion-panel-summary-title-icon {
  float: right;
  padding-left: 35px;
}

.MuiExpansionPanelDetails-root {
  display: flex;
  padding: 0 !important;
}

.input-control .phone-number-input {
  font-weight: 900 !important;
}

.TotalContainer {
  width: 20rem;
  border-top: 1.5px solid #dedede;
  border-bottom: 1px solid #dedede;
  text-align: left;
}

.TotalContainer-WithoutBottom {
  width: 20.1rem;
  border-top: 1px solid $mydna-blue;
  border-bottom: 1px solid $mydna-blue;
  text-align: left;
  margin: auto;
  padding-top: 10px;
  margin-bottom: 15px;
  margin-top: 10px;
}

.payment-details-label {
  width: 20rem;
  text-align: left;
}

.order-details-label {
  width: 20rem;
  text-align: left;
}

.ssl-icon {
  width: 50px;
  height: 40px;
  padding: 10px;
  float: right;
}

.aws-icon {
  width: 80px;
  height: 30px;
  padding: 10px;
  margin-top: 5px;
  float: right;
}

.payment-container {
  margin: auto;
  width: 20rem;
}

.payment-subcontainer {
  margin: auto;
}

.order-total-container {
  border-top: 1px solid $mydna-blue;
  border-bottom: 1px solid $mydna-blue;
  width: 20rem;
  margin-top: 20px;
  padding-top: 10px;
}

.order-total {
  width: 20rem;
  margin: auto;
  height: 2rem;
}

.order-total-left {
  float: left;
}

.order-total-block {
  margin: auto;
  width: 20rem;
}

.order-total-right {
  float: right;
  padding-right: 5px;
  font-size: 17;
  font-weight: bolder;
}

.payment-date {
  font-size: 15px;
  font-weight: 600;
}

.terms-conditions-font {
  font-weight: 600;
}

.payment-expansion-panel {
  width: 21rem;
  margin-bottom: 10px;
}

.payment-expansion-panel-paper {
  text-align: left;
  height: 3rem;
  padding: 10px;
}
.payment-notice {
  float: left;
  margin-top: -5px;
  font-size: 13px;
  font-weight: 500;
  width: 100%;
}
.greyed {
  background: #dedede;
}

.payment-expansion-panel-content-left {
  height: 1.5rem;
  float: left;
  font-family: "AvenirRoman", sans-serif;
}

.payment-expansion-panel-content-right {
  height: 1.5rem;
  float: right;
  text-align: right;
  font-family: "AvenirRoman", sans-serif;
}

.appbar {
  background-color: #081f2d;
}

.TextBox-Title {
  padding: 10px;
  text-align: left;
  margin-left: -10px;
}

.goal-section {
  padding-bottom: 30px;
}

.SubmitButton {
  color: #fff !important;
  font-size: 18px !important;
  border-radius: 6px !important;
  background-color: $mydna-blue;
  font-family: "AvenirRoman", sans-serif;
}

.MuiInputBase-input{
  font-family: "AvenirRoman", sans-serif !important;
}

.BarcodeButton {
  color: $mydna-blue !important;
  margin: 10px;
  font-size: 25px !important;
  margin: 20px !important;
  width: 90px;
  height: 60px;
}
.selected {
  border: 1.5px solid $mydna-blue !important;
}

.VerifyButton {
  margin: 10px;
  margin-top: 18px !important;
  width: 5rem;
  height: 3rem;
  background-color: #fff;
  color: $mydna-blue;
}
.BackButton {
  color: $mydna-blue !important;
  background-color: #fff !important;
  font-size: 18px !important;
  text-transform: none !important;
  font-family: "AvenirRoman", sans-serif;
}

.ReturningUserText {
  font-size: 18px;
  letter-spacing: 1.8px;
  margin-top: -10px;
}

.SignupReturnLink {
  padding-left: 10px;
}

.MuiLinearProgress-colorPrimary {
  background-color: #e9e9e9 !important;
}

.error {
  color: red;
  font: normal normal normal 16px/22px "AvenirRoman", sans-serif;
}

.confirmation-store-icons {
  display: flex;
}

.confirmation-text,
.no-decor {
  font: Black;
  font-size: 24px;
  color: $mydna-blue;
}

.confirmation-image {
  width: 110px;
}

.no-decor {
  text-decoration: none;
}
.store-badges {
  margin: 15px;
  width: 8rem;
  height: 2.5rem;
}

.bottom-margin-div {
  margin-bottom: 200px;
}

.dropdownContent {
  text-align: left;
  padding-left: 5px;
}

.password-help-text {
  font: normal normal normal 13px "AvenirRoman", sans-serif;
  margin: auto;
  width: 25em;
  color: grey;
}

.bottom-margin {
  margin-bottom: 250px;
}

.multilineOptions {
  .sub {
    font-size: 75%;
    font-style: italic;
  }
}

.barcode-helper {
  margin-top: -10px;
  font-size: 12px;
}

.MydnaButton {
  width: 100px !important;
  height: 50px !important;
  border-radius: 5px !important;
  border: 1px solid #b8b8b8 !important;
  margin: 5px !important;
  text-transform: uppercase;
}

.MyDnaButton-Text {
  color: #b8b8b8;
  text-transform: uppercase;
  padding-left: 10px;
  padding-right: 10px;
}

.clicked {
  color: #30b5e8;
  border: none;
}

.barcode {
  width: 155px !important;
}

.selected {
  border: 1.5px solid $mydna-blue !important;
}

.ColoredLine {
  height: 1px;
  border: none;
  color: $mydna-blue;
  background-color: $mydna-blue;
  opacity: 1;
  margin-top: 15px;
  width: 80%;
}

.medication-details-block {
  margin: auto;
}

@media (max-width: $small-device-width) {
  .medication-details-block {
    margin: auto;
    width: 23rem;
  }
}

.medication-details-content {
  text-align: left;
  font: normal normal normal 16px/22px "AvenirRoman", sans-serif;
  letter-spacing: 0px;
  color: #707070;
  opacity: 1;
}

.table-content{
  color: #707070 !important ;
  font: normal normal normal 16px/22px "AvenirRoman", sans-serif;
}

.small{
  font: normal normal normal 14px/20px "AvenirRoman", sans-serif !important;
}

.horizontal-padding {
  padding: 0px 20px 0px 20px;
}

.bottom-padder{
  padding-bottom: 20px;
}

.medication-details-guideline {
  text-align: left;
  letter-spacing: 0px;
  color: #b0b0b0;
  opacity: 1;
  font: normal normal normal 16px/20px "AvenirRoman", sans-serif;
}

.Roman{
  font-family: "AvenirRoman", sans-serif;
}

.body-content{
  font: normal normal normal 16px/20px "AvenirRoman", sans-serif;
}

.mydna-secondary-title {
  color: #000;
  letter-spacing: 0px;
  text-align: center;
  margin: auto;
  font: normal normal normal 18px/24px "AvenirRoman", sans-serif;
}

.meds-table-header{
  font: normal normal normal 16px/24px "AvenirRoman", sans-serif;
  letter-spacing: 0px !important;
  color: #FFFFFF;
  opacity: 1 !important;
  font-weight: 400 !important;
}

.mydna-primary-title {
  color: #e72076;
  text-transform: uppercase;
  font-size: 24px;
  line-height: 32px;
  letter-spacing: 2.4px;
  font-family: MydnaNowBlack;
  margin: auto;
  padding-bottom: 20px;
  padding-top: 30px;
}

.content-container {
  margin: auto;
  padding: 10px;
  height: 100%;
}

.header {
  font-family: "AvenirRoman", sans-serif;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  margin: auto;
}

.left {
  text-align: left;
}

.mydna-title {
  text-align: center;
  font: normal normal medium 20px/58px Now;
  letter-spacing: 2px;
  color: #959595;
  text-transform: uppercase;
  opacity: 1;
}

.radio-label {
  text-align: left;
  font: normal normal normal 16px/24px "AvenirRoman", sans-serif;
  letter-spacing: 0px;
  color: #000000 !important;
  opacity: 1 ;
  margin-bottom: 10px;
}

.padder{
  padding: 10px;
}

.greyedText {
  color: #959595;
}

.smallFont{
  font-size: 14px;
  letter-spacing: 0.5px;
}

.width{
  margin: auto
}

.AvenirStandard{
  font: normal normal normal 16px/24px AvenirBlack
}

.under-age-warning-block{
  width: 21rem;
  margin: auto;
}

.under-age-warning-header{
  text-align: left;
  font: normal normal 900 12px/24px AvenirBlack;
  letter-spacing: 0px;
  color: #707070;
}

.under-age-warning-content{
  margin-top: -10px;
  text-align: left;
  font: normal normal normal 12px/24px "AvenirRoman", sans-serif;
  letter-spacing: 0px;
  color: #707070;
}

.MuiFormLabel-root{
  font: normal normal normal 16px/24px "AvenirRoman", sans-serif !important;
}

.width-meds{
  width: 23rem;
  margin: auto;
}

.subscription-plan {
  margin-bottom: 28px;
  @media (max-width: $small-device-width) {
    .MuiToggleButtonGroup-root {
      display: block;
      .MuiToggleButtonGroup-grouped {
        margin-bottom: 30px;
      }
    }
  }
  .MuiToggleButton-root {
    text-transform: none !important;
  }
}
/*
.MuiButton-root{
  font-family: AvenirRoman !important;
}
*/