body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: AvenirBlack
}

@font-face {
  font-family: 'AvenirBlack';
  src: url('./Themes/fonts/avenir/black/AvenirLTStd-Black.eot');
  src: local('AvenirBlack'), url('./Themes/fonts/avenir/black/AvenirLTStd-Black.eot') format('embedded-opentype'),
         url('./Themes/fonts/avenir/black/AvenirLTStd-Black.woff2') format('woff2'),
         url('./Themes/fonts/avenir/black/AvenirLTStd-Black.woff') format('woff'),
         url('./Themes/fonts/avenir/black/AvenirLTStd-Black.ttf') format('truetype'),
         url('./Themes/fonts/avenir/black/AvenirLTStd-Black.svg#AvenirBlack') format('svg');
}

@font-face {
  font-family: 'AvenirBook';
  src: url('./Themes/fonts/avenir/book/AvenirLTStd-Book.eot');
  src: local('AvenirBook'), url('./Themes/fonts/avenir/book/AvenirLTStd-Book.eot') format('embedded-opentype'),
         url('./Themes/fonts/avenir/book/AvenirLTStd-Book.woff2') format('woff2'),
         url('./Themes/fonts/avenir/book/AvenirLTStd-Book.woff') format('woff'),
         url('./Themes/fonts/avenir/book/AvenirLTStd-Book.ttf') format('truetype'),
         url('./Themes/fonts/avenir/book/AvenirLTStd-Book.otf') format('otf'),
         url('./Themes/fonts/avenir/book/AvenirLTStd-Book.svg#AvenirBook') format('svg');
}

@font-face {
  font-family: 'AvenirLight';
  src: url('./Themes/fonts/avenir/light/AvenirLTStd-Light.eot');
  src: local('AvenirLight'), url('./Themes/fonts/avenir/light/AvenirLTStd-Light.eot') format('embedded-opentype'),
         url('./Themes/fonts/avenir/light/AvenirLTStd-Light.woff2') format('woff2'),
         url('./Themes/fonts/avenir/light/AvenirLTStd-Light.woff') format('woff'),
         url('./Themes/fonts/avenir/light/AvenirLTStd-Light.ttf') format('truetype'),
         url('./Themes/fonts/avenir/light/AvenirLTStd-Light.svg#AvenirLight') format('svg');
}

@font-face {
  font-family: 'AvenirHeavy';
  src: url('./Themes/fonts/avenir/heavy/AvenirLTStd-Heavy.eot');
  src: local('AvenirHeavy'), url('./Themes/fonts/avenir/heavy/AvenirLTStd-Heavy.eot') format('embedded-opentype'),
       url('./Themes/fonts/avenir/heavy/AvenirLTStd-Heavy.woff2') format('woff2'),
       url('./Themes/fonts/avenir/heavy/AvenirLTStd-Heavy.woff') format('woff'),
       url('./Themes/fonts/avenir/heavy/AvenirLTStd-Heavy.ttf') format('truetype'),
       url('./Themes/fonts/avenir/heavy/AvenirLTStd-Heavy.svg#AvenirHeavy') format('svg');
}

@font-face {
  font-family: 'AvenirRoman';
  src: url('./Themes/fonts/avenir/roman/AvenirLTStd-Roman.eot');
  src: local('AvenirRoman'), url('./Themes/fonts/avenir/roman/AvenirLTStd-Roman.eot') format('embedded-opentype'),
         url('./Themes/fonts/avenir/roman/AvenirLTStd-Roman.woff2') format('woff2'),
         url('./Themes/fonts/avenir/roman/AvenirLTStd-Roman.woff') format('woff'),
         url('./Themes/fonts/avenir/roman/AvenirLTStd-Roman.ttf') format('truetype'),
         url('./Themes/fonts/avenir/roman/AvenirLTStd-Roman.svg#AvenirRoman') format('svg');
}

@font-face {
  font-family: 'AvenirMedium';
  src: local('AvenirMedium'), url('./Themes/fonts/avenir/medium/AvenirLTStdMedium.eot');
  src: local('AvenirMedium'), url('./Themes/fonts/avenir/medium/AvenirLTStdMedium.eot') format('embedded-opentype'),
         url('./Themes/fonts/avenir/medium/AvenirLTStdMedium.woff2') format('woff2'),
         url('./Themes/fonts/avenir/medium/AvenirLTStdMedium.woff') format('woff'),
         url('./Themes/fonts/avenir/medium/AvenirLTStdMedium.ttf') format('truetype'),
         url('./Themes/fonts/avenir/medium/AvenirLTStdMedium.svg#AvenirMedium') format('svg');
}

@font-face {
  font-family: 'MydnaNowBlack';
  src: local('MydnaNowBlack'), url('./Themes/fonts/now/black/mydna-now-black.eot');
  src: local('MydnaNowBlack'), url('./Themes/fonts/now/black/mydna-now-black.eot') format('embedded-opentype'),
         url('./Themes/fonts/now/black/mydna-now-black.woff2') format('woff2'),
         url('./Themes/fonts/now/black/mydna-now-black.woff') format('woff'),
         url('./Themes/fonts/now/black/mydna-now-black.ttf') format('truetype'),
         url('./Themes/fonts/now/black/mydna-now-black.svg#MydnaNowBlack') format('svg');
}

@font-face {
  font-family: 'NowRegular';
  src: url("./Themes/fonts/now/regular/now-regular.eot");
  src: local('NowRegular'), url("./Themes/fonts/now/regular/now-regular.eot?#iefix") format('embedded-opentype'),
      url("./Themes/fonts/now/regular/now-regular.woff2") format('woff2'),
      url("./Themes/fonts/now/regular/now-regular.woff") format('woff'),
      url("./Themes/fonts/now/regular/now-regular.ttf") format('truetype'),
      url("./Themes/fonts/now/regular/now-regular.svg#NowBlack") format('svg');
}  

@font-face {
  font-family: 'NowThin';
  src: local('NowThin'), url('./Themes/fonts/now/thin/now-thin.eot');
  src: local('NowThin'), url('./Themes/fonts/now/thin/now-thin.eot') format('embedded-opentype'),
         url('./Themes/fonts/now/thin/now-thin.woff2') format('woff2'),
         url('./Themes/fonts/now/thin/now-thin.woff') format('woff'),
         url('./Themes/fonts/now/thin/now-thin.ttf') format('truetype'),
         url('./Themes/fonts/now/thin/now-thin.svg#NowThin') format('svg');
}

@font-face {
  font-family: 'NowBlack';
  src: url("./Themes/fonts/now/black/mydna-now-black.eot");
  src: local('NowBlack'), url("./Themes/fonts/now/black/mydna-now-black.eot?#iefix") format('embedded-opentype'),
      url("./Themes/fonts/now/black/mydna-now-black.woff2") format('woff2'),
      url("./Themes/fonts/now/black/mydna-now-black.woff") format('woff'),
      url("./Themes/fonts/now/black/mydna-now-black.ttf") format('truetype'),
      url("./Themes/fonts/now/black/mydna-now-black.svg#NowBlack") format('svg');
}