h1 {
    margin-bottom: 1rem;
  }
  
  em {
    background: cyan;
    font-style: normal;
  }
  
  .container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 1rem;
  }
  
  .react-autosuggest__container {
    position: relative;
    margin-bottom: 1rem;
  }
  
  .react-autosuggest__input {
    width: 100%;
    box-sizing: border-box;
    padding: 20px 20px;
    text-align: left;
    font: normal normal normal 16px/24px 'AvenirRoman', sans-serif;
    letter-spacing: 0px;
    color: #707070;
    opacity: 1;
    border: 1px solid #aaa;
    border-radius: 4px;
  }
  
  .react-autosuggest__input--focused {
    outline: none;
  }
  
  .react-autosuggest__input--open {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
  
  .react-autosuggest__suggestions-container {
    display: none;
  }
  
  .react-autosuggest__suggestions-container--open {
    display: block;
    box-sizing: border-box;
    top: 51px;
    width: 100%;
    border: 1px solid #aaa;
    background-color: #fff;
    text-align: left;
    font: normal normal normal 16px/24px 'AvenirRoman', sans-serif;
    letter-spacing: 0px;
    color: #707070;
    opacity: 1;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    z-index: 2;
    text-align: left;
  }
  
  .react-autosuggest__section-title {
    padding: 10px 20px;
    font-weight: 600;
  }
  
  .react-autosuggest__suggestions-list {
    margin: 0;
    padding: 0;
    list-style-type: none;
  }
  
  .react-autosuggest__suggestion {
    cursor: pointer;
    padding: 10px 20px;
  }
  
  .react-autosuggest__suggestion--highlighted {
    background-color: #ddd;
  }
  